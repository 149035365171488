import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
  
    <h1>OOPS....NOT FOUND</h1>
    <img src="https://media.giphy.com/media/zvBuF2oYRErVS/giphy.gif" />
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage